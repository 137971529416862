import React from 'react'

import Default from './../layouts/Default/Default'
import Intro from './../components/Intro/Intro'
import OpenSource from '../components/OpenSource/OpenSource'
import SEO from '../utils/seo'

const about = ({ className, ...restProps }) => {
  return (
    <Default>
      <SEO
        lang="en"
        title="About"
        description="I am a UX/UI designer and front-end web developer. I build and design digital products, mostly website. I love building website using React and Node.js"
        keywords={[
          `website`,
          `build`,
          `create`,
          `web`,
          `mobile`,
          `app`,
          `react`,
          `front-end`,
          `developer`,
          `programmer`,
          `bandung`,
          `agency`,
          `designer`,
          `application`,
          `react`,
        ]}
        author="Ongki 'vasilenka' Herlambang"
      />
      <Intro />
      <OpenSource />
    </Default>
  )
}

export default about
