import styles from './OpenSource.module.scss'
import React from 'react'
import cx from 'classnames'

import Text from '../Text/Text'
import Link from '../Link/Link'
import Container from '../../layouts/Container/Container'

let items = [
  {
    name: 'Time Void',
    description:
      "Todo list with time tracker so you'll be aware more of how you use your time and use it as a measurements for self improvement",
    url: 'https://github.com/vasilenka/void',
  },
  {
    name: 'Tinker CLI',
    description:
      'Scaffold your React using Gatsby, create-react-app, or Next.js using the invoker starter.',
    url: 'https://github.com/meridianid/tinker-cli',
  },
  {
    name: 'Invoker Gatsby',
    description:
      'A starter-kit for building static site using React with Gatsby.',
    url: 'https://github.com/meridianid/invoker-gatsby',
  },
  {
    name: 'Invoker Next.js',
    description: 'A starter-kit for building website using React with Next.js.',
    url: 'https://github.com/meridianid/invoker-next',
  },
  {
    name: 'Invoker',
    description:
      'A base React component libray with design tokens and SASS mixins. Used in all the invoker based starter.',
    url: 'https://github.com/vasilenka/invoker',
  },
]

const OpenSource = ({ children, className, ...restProps }) => {
  return (
    <Container narrow className={cx(styles.root)} {...restProps}>
      <header style={{ marginBottom: 64 }}>
        <Text heading3 as="h2">
          Open Source Projects
        </Text>
      </header>
      <ul className={styles.projectContainer}>
        {items.map(project => (
          <li key={project.url} className={styles.project}>
            <Link external to={project.url}>
              <Text heading2 as="h3" style={{ marginBottom: 8 }}>
                {project.name}
              </Text>
              <Text
                medium
                as="p"
                style={{ color: '#484848', maxWidth: '60ch' }}
              >
                {project.description}
              </Text>
            </Link>
          </li>
        ))}
      </ul>
    </Container>
  )
}

export default OpenSource
